/**
 * Parses an string to an usable uri string.
 *
 * @param {String} str The string to parse.
 *
 * @returns {String} The uri version of str.
 */
export const parseToUri = str => {
    const map = {
        ' ': '_',
        ß: 'ss',
        ä: 'ae',
        ö: 'oe',
        ü: 'ue'
    };
    // eslint-disable-next-line security/detect-non-literal-regexp
    const regex = new RegExp(Object.keys(map).join('|'), 'gu');

    return decodeURIComponent(str.toLowerCase().replace(regex, match => map[String(match)]));
};

/**
* Configures Quill to not add styles on paste.
*
* @param {Object} quill Quill editor object.
*/
export const configureQuill = quill => {
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        const allowed = ['header', 'bold', 'italic', 'underline', 'link', 'list'];

        delta.ops.forEach(op => {
            if (op.attributes) {
                Object.keys(op.attributes).forEach(key => {
                    if (!allowed.includes(key)) {
                        // eslint-disable-next-line no-param-reassign
                        delete op.attributes[String(key)];
                    }

                    // eslint-disable-next-line @nfq/no-magic-numbers
                    if (key === 'header' && op.attributes.header !== 4) {
                        // eslint-disable-next-line no-param-reassign
                        op.attributes.header = 3;
                    }
                });
            }
        });

        return delta;
    });
};

export const quillToolbar = [
    [{header: [3, 4, false]}],
    ['bold', 'italic', 'underline', 'link'],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['clean']
];