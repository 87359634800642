import styled from 'styled-components';

import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

const ModuleMargin = styled.div`
    margin-bottom: ${({isInGroup, noCopy}) => {
        if (isInGroup && noCopy) {
            return clampCss(-16, 'm', true);
        } else if (isInGroup) {
            return clampCss(64, 'm');
        }

        return clampCss(96, 'm');
    }};
    margin-top: ${({isInGroup}) => (isInGroup ? clampCss(64, 'm') : clampCss(96, 'm'))};

    &:first-child {
        margin-top: 0px;
    }

    ${MEDIA.TABLET.UP} {
        margin-bottom: ${({isInGroup, noCopy}) => {
        if (isInGroup && noCopy) {
            return clampCss(-64, 't', true);
        } else if (isInGroup) {
            return clampCss(112, 't');
        }

        return clampCss(142, 't');
    }};
        margin-top: ${({isInGroup}) => (isInGroup ? clampCss(112, 't') : clampCss(142, 't'))};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: ${({isInGroup, noCopy}) => {
        if (isInGroup && noCopy) {
            return `max(${pxToVw(-132, 'd')}, -132px)`;
        } else if (isInGroup) {
            return `max(180px, ${pxToVw(180, 'd')})`;
        }

        return `max(224px, ${pxToVw(224, 'd')})`;
    }};
        margin-top: ${({isInGroup}) => (isInGroup ? `max(180px, ${pxToVw(180, 'd')})` : `max(224px, ${pxToVw(224, 'd')})`)};
    }
`;

const DividerMargin = styled.div`
    margin-bottom: ${({isInGroup}) => (isInGroup ? clampCss(-32, 'm', true) : clampCss(-64, 'm', true))};
    margin-top: ${({isInGroup}) => (isInGroup ? clampCss(-32, 'm', true) : clampCss(-64, 'm', true))};

    &:first-child {
        margin-top: 44px;
    }

    &:last-child {
        margin-bottom: 0px;
    }

    ${MEDIA.TABLET.UP} {
        margin-bottom: ${({isInGroup}) => (isInGroup ? clampCss(-8, 't', true) : clampCss(-38, 't', true))};
        margin-top: ${({isInGroup}) => (isInGroup ? clampCss(-80, 't', true) : clampCss(-110, 't', true))};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: ${({isInGroup}) => (isInGroup ? `max(${pxToVw(-20, 'd')}, -20px)` : `max(${pxToVw(-64, 'd')}, -64px)`)};
        margin-top: ${({isInGroup}) => (isInGroup ? `max(${pxToVw(-148, 'd')}, -148px)` : `max(${pxToVw(-192, 'd')}, -192px)`)};
    }
`;

const PageHeadMargin = styled.div`
    margin-bottom: ${({isInGroup}) => (isInGroup ? '0' : clampCss(-32, 'm', true))};
    margin-top: ${({isInGroup}) => (isInGroup ? clampCss(64, 'm') : clampCss(96, 'm'))};

    &:first-child {
        margin-top: 0px;
    }

    ${MEDIA.TABLET.UP} {
        margin-bottom: ${({isInGroup}) => (isInGroup ? clampCss(-48, 't', true) : clampCss(-78, 't', true))};
        margin-top: ${({isInGroup}) => (isInGroup ? clampCss(112, 't') : clampCss(142, 't'))};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: ${({isInGroup}) => (isInGroup ? `max(${pxToVw(-116, 'd')}, -116px)` : `max(${pxToVw(-160, 'd')}, -160px)`)};
        margin-top: ${({isInGroup}) => (isInGroup ? `max(180px, ${pxToVw(180, 'd')})` : `max(224px, ${pxToVw(224, 'd')})`)};
    }
`;

export {
    DividerMargin,
    ModuleMargin,
    PageHeadMargin
};