import styled from 'styled-components';

import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Gets P Sytles.
 *
 * @param {String} mod Module Type.
 *
 * @returns {String} Style string.
 */
const getPStyles = mod => {
    switch (mod) {
        case 'Accordion':
        case 'AccordionImage':
            return `
                margin: ${clampCss(16.8, 'm')} 0;
                text-align: inherit;

                &:last-child {
                    margin-bottom: ${clampCss(15.4, 'm')};
                }

                & > strong {
                    ${FONTS.SEMIBOLD}
                }

                ${MEDIA.TABLET.UP} {
                    margin: ${clampCss(16.8, 't')} 0;

                    &:last-child {
                        margin-bottom: ${clampCss(15.4, 't')};
                    }
                }

                ${MEDIA.DESKTOP.UP} {
                    margin: max(19.2px, ${pxToVw(19.2, 'd')}) 0;

                    &:last-child {
                        margin-bottom: max(7px, ${pxToVw(7, 'd')});
                    }
                }
            `;
        case 'AccordionUi':
            return `
                margin: ${clampCss(16.8, 'm')} 0;
                text-align: inherit;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: ${clampCss(40, 'm')};
                }

                & > strong {
                    ${FONTS.SEMIBOLD}
                }

                ${MEDIA.TABLET.UP} {
                    margin: ${clampCss(16.8, 't')} 0;

                    &:last-child {
                        margin-bottom: ${clampCss(40, 't')};
                    }
                }

                ${MEDIA.DESKTOP.UP} {
                    margin: max(19.2px, ${pxToVw(19.2, 'd')}) 0;

                    &:last-child {
                        margin-bottom: max(40px, ${pxToVw(40, 'd')});
                    }
                }
            `;
        default:
            return `
                margin: ${clampCss(16.8, 'm')} 0;
                text-align: inherit;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                & > strong {
                    ${FONTS.SEMIBOLD}
                }

                ${MEDIA.TABLET.UP} {
                    margin: ${clampCss(16.8, 't')} 0;
                }

                ${MEDIA.DESKTOP.UP} {
                    margin: max(19.2px, ${pxToVw(19.2, 'd')}) 0;
                }
            `;
    }
};

/**
 * Gets H3 Sytles.
 *
 * @param {String} mod   Module Type.
 * @param {Object} theme The styled theme.
 *
 * @returns {String} Style string.
 */
const getH3Styles = (mod, theme) => {
    switch (mod) {
        default:
            return `
                ${FONTS.REGULAR}
                color: ${theme.colors.textColor};
                font-size: ${clampCss(20, 'm')};
                letter-spacing: ${clampCss(1.2, 'm')};
                line-height: ${clampCss(24, 'm')};
                margin: ${clampCss(16, 'm')} 0;
                text-align: inherit;

                &:first-child {
                    margin-top: ${clampCss(18, 'm')};
                }

                & > strong {
                    ${FONTS.SEMIBOLD}
                }

                ${MEDIA.TABLET.UP} {
                    font-size: ${clampCss(20, 't')};
                    letter-spacing: ${clampCss(1.2, 't')};
                    line-height: ${clampCss(24, 't')};
                    margin: ${clampCss(16, 't')} 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                ${MEDIA.DESKTOP.UP} {
                    font-size: max(20px, ${pxToVw(20, 'd')});
                    letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
                    line-height: max(24px, ${pxToVw(24, 'd')});
                    margin: max(16px, ${pxToVw(16, 'd')}) 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            `;
    }
};

/**
 * Gets H4 Sytles.
 *
 * @param {String} mod   Module Type.
 * @param {Object} theme The styled theme.
 *
 * @returns {String} Style string.
 */
const getH4Styles = (mod, theme) => {
    switch (mod) {
        default:
            return `
                ${FONTS.REGULAR}
                color: ${theme.colors.textColor};
                font-size: ${clampCss(16, 'm')};
                letter-spacing: ${clampCss(1.1, 'm')};
                line-height: ${clampCss(22, 'm')};
                margin: ${clampCss(16, 'm')} 0;
                text-align: inherit;

                &:first-child {
                    margin-top: 0;
                }

                & > strong {
                    ${FONTS.SEMIBOLD}
                }

                ${MEDIA.TABLET.UP} {
                    font-size: ${clampCss(16, 't')};
                    letter-spacing: ${clampCss(1.1, 't')};
                    line-height: ${clampCss(22, 't')};
                    margin: ${clampCss(16, 't')} 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                ${MEDIA.DESKTOP.UP} {
                    font-size: max(16px, ${pxToVw(16, 'd')});
                    letter-spacing: max(1.1px, ${pxToVw(1.1, 'd')});
                    line-height: max(22px, ${pxToVw(22, 'd')});
                    margin: max(16px, ${pxToVw(16, 'd')}) 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            `;
    }
};

/**
 * Gets UL Sytles.
 *
 * @param {String} mod   Module Type.
 * @param {Object} theme The styled theme.
 *
 * @returns {String} Style string.
 */
const getULStyles = (mod, theme) => {
    switch (mod) {
        default:
            return `
                margin: ${clampCss(18, 'm')} 6px;
                padding: 0 0 0 ${clampCss(12, 'm')};
                text-align: inherit;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                & > li {
                    ${FONTS.REGULAR}
                    color: ${theme.colors.textColor};
                    letter-spacing: ${clampCss(0.5, 'm')};
                    line-height: ${clampCss(16.8, 'm')};
                    margin: ${clampCss(8, 'm')} 0;
                }

                & > strong {
                    ${FONTS.SEMIBOLD}
                }

                ${MEDIA.TABLET.UP} {
                    margin: ${clampCss(18, 't')} 6px;
                    padding: 0 0 0 ${clampCss(12, 't')};

                    & > li {
                        color: ${theme.colors.textColor};
                        letter-spacing: ${clampCss(0.5, 't')};
                        line-height: ${clampCss(16.8, 't')};
                        margin: ${clampCss(8, 't')} 0;
                    }
                }

                ${MEDIA.DESKTOP.UP} {
                    margin: max(24px, ${pxToVw(24, 'd')}) 6px;
                    padding: 0 0 0 max(12px, ${pxToVw(12, 'd')});

                    & > li {
                        color: ${theme.colors.textColor};
                        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
                        line-height: max(16.8px, ${pxToVw(16.8, 'd')});
                        margin: max(8px, ${pxToVw(8, 'd')}) 0;
                    }
                }
            `;
    }
};

/**
 * Gets UL Sytles.
 *
 * @param {String} mod   Module Type.
 * @param {Object} theme The styled theme.
 *
 * @returns {String} Style string.
 */
const getAStyles = (mod, theme) => {
    switch (mod) {
        default:
            return `
                ${FONTS.SEMIBOLD}
                color: ${theme.colors.link};
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                & > strong {
                    ${FONTS.SEMIBOLD}
                }
            `;
    }
};

export const P = styled.p`
    ${({mod}) => getPStyles(mod)}
`;

export const H3 = styled.h3`
    ${({mod, theme}) => getH3Styles(mod, theme)}
`;

export const H4 = styled.h4`
    ${({mod, theme}) => getH4Styles(mod, theme)}
`;

export const UL = styled.ul`
    ${({mod, theme}) => getULStyles(mod, theme)}
`;

export const A = styled.a`
    ${({mod, theme}) => getAStyles(mod, theme)}
`;