import React, {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {DividerMargin} from 'Frontend/components/ModuleMargins';

import {parseToUri} from 'Admin/utils/formParsers';
import {Divider as DividerIcon} from 'Frontend/assets/images/icons';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Gets the right padding for the actual position.
 *
 * @param {String} position The position string.
 * @returns {String} The calculated padding.
 */
const getPadding = position => {
    if (position === 'flex-start') {
        return `0 ${clampCss(57.594, 't')} 0 0`;
    }
    if (position === 'flex-end') {
        return `0 0 0 ${clampCss(57.594, 't')}`;
    }

    return '0';
};

/**
 * Divider
 *
 * @property {*} prop Prop comment.
 *
 * @class Divider
 * @extends {Component}
 */
class Divider extends Component {
    static propTypes = {
        className: PropTypes.string,
        isInGroup: PropTypes.bool,
        name: PropTypes.string,
        position: PropTypes.string
    }

    static defaultProps = {
        className: '',
        isInGroup: false,
        name: null,
        position: 'center'
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Divider
     */
    render() {
        const {className, isInGroup, name, position} = this.props;

        return (
            <DividerElement className={className} isInGroup={isInGroup} position={position}>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                {name && <a id={parseToUri(name)} />}
                <DividerPlacing>
                    <DividerIcon />
                </DividerPlacing>
            </DividerElement>
        );
    }
}

export default Divider;

const DividerElement = styled(DividerMargin)`
    display: flex;
    justify-content: center;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0);
    width: 100%;

    ${MEDIA.TABLET.UP} {
        justify-content: ${({position}) => position};
        padding: ${({position}) => getPadding(position)};
        width: 100%;
    }

    ${MEDIA.DESKTOP.UP} {
        width: max(1320px, ${pxToVw(1320, 'd')});
    }
`;

const DividerPlacing = styled.div`
    align-items: center;
    display: flex;
    height: ${clampCss(82, 'm')};
    justify-content: center;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(82, 't')};
        width: calc(60% - 16px);
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(82px, ${pxToVw(82, 'd')});
        width: max(628px, ${pxToVw(628, 'd')});
    }
`;