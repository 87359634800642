/* eslint-disable react/destructuring-assignment */
import React from 'react';

import HtmlToReact from 'html-to-react';
import Link from 'next/link';

import {A, H3, H4, P, UL} from 'Frontend/components/Text';

/**
 * Determines if node is valid.
 *
 * @returns {Boolean} If valid or not;
 */
export const isValidNode = () => true;

const ReactDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

export const processingInstructions = [
    {
        processNode(node, children, index) {
            const href = node?.attribs?.href;
            const rel = node?.attribs?.rel;
            const target = node?.attribs?.target;
            const props = {};

            if (href.includes('http')) {
                props.rel = rel;
                props.target = target;
            }

            return React.createElement(
                Link,
                {
                    href,
                    key: index,
                    passHref: true
                },
                React.createElement(A, props, children)
            );
        },
        shouldProcessNode(node) {
            return node.name && node.name === 'a';
        }
    },
    {
        processNode(node, children, index) {
            const mod = node?.parent?.attribs['data-module'];

            return React.createElement(H3, {
                key: index,
                mod
            }, children);
        },
        shouldProcessNode(node) {
            return node.name && node.name === 'h3';
        }
    },
    {
        processNode(node, children, index) {
            const mod = node?.parent?.attribs['data-module'];

            return React.createElement(H4, {
                key: index,
                mod
            }, children);
        },
        shouldProcessNode(node) {
            return node.name && node.name === 'h4';
        }
    },
    {
        processNode(node, children, index) {
            const mod = node?.parent?.attribs['data-module'];

            return React.createElement(P, {
                key: index,
                mod
            }, children);
        },
        shouldProcessNode(node) {
            return node.name && node.name === 'p';
        }
    },
    {
        processNode(node, children, index) {
            const mod = node?.parent?.attribs['data-module'];

            return React.createElement(UL, {
                key: index,
                mod
            }, children);
        },
        shouldProcessNode(node) {
            return node.name && node.name === 'ul';
        }
    },
    {
        processNode(node, children, index) {
            // eslint-disable-next-line no-param-reassign
            node.attribs.height = '100%';
            // eslint-disable-next-line no-param-reassign
            node.attribs.width = '100%';

            return ReactDefinitions.processDefaultNode(node, children, index);
        },
        shouldProcessNode(node) {
            return node.name && node.name === 'iframe';
        }
    },
    {
        processNode: ReactDefinitions.processDefaultNode,
        shouldProcessNode() {
            return true;
        }
    }
];